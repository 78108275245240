import React from "react"
import StyledBanner from "./style/banner"
import data from '../../content/about/banner.json'
import bgImg from "../../assets/images/about/banner.jpg"
import Breadcumb from "../common/Breadcrumb"

const AboutBanner = () => {
    const crumbs = [
        { name: 'Home', link: '/' },
        { name: 'About Us', link: '' }
      ]
    return (
        <StyledBanner style={{
            backgroundImage: `url(${bgImg})`
        }}>
            <div className="banner about">
                <div className="container">
                    <h1>About Us</h1>
                    <p className="desc">{data.description}</p>
                    <Breadcumb crumbs={crumbs} />
                </div>
            </div>
        </StyledBanner>
    )
};

export default AboutBanner