import React from "react"
import StyledVendorBenefits from "./style/vendor-benefits";

const VendorBenefits = () => {
    return (
        <StyledVendorBenefits>
            <div className="main-container">
                <h3>Benefits To Vendors</h3>
                <p>Connect with customers who are seeking services</p>
                <ul className="benefits">
                    <li>
                        <h4>Increased visibility</h4>
                        <p>By listing their services on the website of Housefull Events and Hospitality Management Services company, vendors can reach a wider audience and increase their visibility in the market.</p>
                    </li>
                    <li>
                        <h4>Better marketing</h4>
                        <p>We supports event vendors to advertise their services and can promote the business. They can showcase their services through photos, videos and descriptions</p>
                    </li>
                    <li>
                        <h4>Streamlined communication</h4>
                        <p>With features such as messaging and chatting , vendors can easily communicate with potential customers and manage their bookings in one place.</p>
                    </li>
                    <li>
                        <h4>Enhanced credibility</h4>
                        <p>
                            Being listed on an event website can improve a vendor's credibility, as it shows that they are a trusted and reliable provider. Customers are more likely to trust and do business with a vendor who is listed on a reputable event website.
                        </p>
                    </li>
                </ul>
            </div>
        </StyledVendorBenefits>
    )
};

export default VendorBenefits
