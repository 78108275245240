import React from "react"
import StyledUserBenefits from "./style/user-benefits";

const UserBenefits = () => {
    return (
        <StyledUserBenefits>
            <div className="main-container">
                <h3>Benefits To Customers</h3>
                <p>Discover & connect with top-rated vendors</p>
                <ul className="benefits">
                    <li>
                        <h4>Easy search and comparison</h4>
                        <p>We make it easy for customers to search for vendors based on their needs, location and budget. They can also compare vendors side-by-side to make an informed decision.</p>
                    </li>
                    <li>
                        <h4>Wider selection</h4>
                        <p>We can provide customers with a wider selection of vendors, giving them more options to choose from and increasing their chances of finding the right vendor for their event.</p>
                    </li>
                    <li>
                        <h4>User friendly interface</h4>
                        <p>Our website is easy to navigate and understand with clear menus and links that take users to the information they need quickly and efficiently.  It is a simple, intuitive, and efficient way for users to interact with the website.</p>
                    </li>
                    <li>
                        <h4>Reliable information</h4>
                        <p>With vendor profiles and customer reviews, can provide customers with reliable information about vendors, helping them make informed decisions and avoid potential scams or disappointment.</p>
                    </li>
                </ul>
            </div>
        </StyledUserBenefits>
    )
};

export default UserBenefits
