import tw, { styled } from 'twin.macro';
const StyledWhatWeDo = styled.section`
    ${tw`md:py-32 py-12 bg-primary-dark bg-opacity-5`};
    .main-container {
        ${tw`flex flex-col items-center justify-center container mx-auto md:px-8 px-5`};
        h3 {
            ${tw`md:text-4xl text-2xl font-bold text-center pb-4`}
        }
        p {
            ${tw`md:text-lg text-base text-center font-medium text-muted pb-4 leading-normal`}
        }
    }
`;
export default StyledWhatWeDo;