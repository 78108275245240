import React from "react"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import AboutBanner from "../components/about/AboutBanner"
import About from "../components/about/About"
import WhatWeDo from "../components/about/WhatWeDo"
import VendorBenefits from "../components/about/VendorBenefits"
import UserBenefits from "../components/about/UserBenefits"
const AboutPage = () => {
  return (
    <Layout bgWhite={false}>
      <AboutBanner />
      <About />
      <WhatWeDo />
      <VendorBenefits />
      <UserBenefits />
    </Layout>
  )
}

export const Head = () => (
  <SEO title="About Us - Housefull Events & Hospitality Management Services" description="Streamline your event planning with Housefull Events &  Hospitality Management Services. From venue selection to guest coordination, we handle it all." />
)

export default AboutPage
