import React from "react"
import StyledWhatWeDo from "./style/what-we-do"
import data from "../../content/about/what-we-do.json"
const WhatWeDo = () => {
    return (
        <StyledWhatWeDo>
            <div className="main-container">
                <h3>{data.heading}</h3>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </div>
        </StyledWhatWeDo>
    )
};

export default WhatWeDo
