import tw, { styled } from 'twin.macro';
const StyledUserBenefits = styled.section`
    ${tw`md:py-32 py-12 bg-primary-dark bg-opacity-5`};
    .main-container {
        ${tw`flex flex-col items-center justify-center container mx-auto md:px-8 px-5`};

        h3 {
            ${tw`md:text-4xl text-2xl font-bold text-center`}
        }

        p {
            ${tw`md:text-lg text-base text-center font-medium text-muted pb-4 leading-normal`}
        }

        .benefits {
            ${tw`grid md:grid-cols-4 grid-cols-1 gap-8 pt-4`}

            li {
                ${tw`bg-white rounded shadow-md hover:(shadow-xl) transition-all duration-[0.3s] p-6 flex flex-col gap-4 text-left`}

                h4 {
                    ${tw`text-primary-dark text-xl font-bold`}
                }

                p {
                    ${tw`text-base font-normal text-left`}
                }
            }
        }
    }
`;
export default StyledUserBenefits;